"use client";

import { Nullable } from "@/types";
import { ReactNode } from "react";
import { selectUniverse } from "./_actions";

type Universe = {
  tag: string;
  title: string;
};

export const UniversePicker = ({
  tag,
  title,
  children,
}: Nullable<Universe> & { children: ReactNode }) => {
  return (
    <button
      className="grid justify-center gap-2"
      onClick={async () => {
        await selectUniverse(tag);
      }}
    >
      {children}
      <span>{title}</span>
    </button>
  );
};
